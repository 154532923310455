<template>
    <div class="address-selector">
        <van-nav-bar title="地址列表"/>
        <div class="address-selector-list">
            <van-list
                    v-model="loading"
                    :finished="finished"
                    :finished-text="finishedText">
                <div class="van-address-list" v-if="list.length>0">
                    <div role="radiogroup" class="van-radio-group">
                        <van-swipe-cell v-for="(item ,index) in list" v-bind:key="index">
                            <template slot="default">
                                <div role="button" class="van-cell van-cell--clickable van-address-item">
                                    <div class="van-cell__value van-cell__value--alone van-address-item__value"
                                         @click="onChange(item)">
                                        <div role="radio" :tabindex="index" aria-checked="true" class="van-radio">
                                            <div :class="{'van-radio__icon':true,'van-radio__icon--round':true,'van-radio__icon--checked':item.id==address.id}"
                                                 style="font-size: 16px;">
                                                <i class="van-icon van-icon-success"></i>
                                            </div>
                                            <span class="van-radio__label">
                                                <div class="van-address-item__name">
                                                    {{item.consignee}}，{{item.mobile}}
                                                </div>
                                                <div class="van-address-item__address">
                                                    <van-tag plain type="warning" v-if="item.isDefault==1">默认</van-tag>
                                                    <van-tag plain type="primary" v-if="item.type==1">直送</van-tag>
                                                    <van-tag plain type="success" v-if="item.type==2">自提</van-tag>
                                                    {{item.city}}{{item.county}}{{item.address}}
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                    <i class="van-icon van-icon-edit van-address-item__edit" @click="onEdit(item)"></i>
                                </div>
                            </template>
                            <template slot="right">
                                <van-button square type="danger" icon="delete" text="删除" @click="onDelete(item)"
                                            style="height: 100%"/>
                            </template>
                        </van-swipe-cell>
                    </div>
                </div>
            </van-list>
        </div>
        <div class="address-selector-pagination" v-show="pages>0">
            <van-pagination
                    v-model="page"
                    :page-count="pages"
                    mode="simple"
                    @change="load"
            />
        </div>
        <div class="address-selector-btn">
            <van-button type="info" icon="plus" size="large" @click="onAdd"
                        style="position:fixed;bottom:0;"
                        text="新增地址"/>
        </div>
        <div class="address-selector-popup">
            <!-- 新增修改地址 -->
            <van-popup v-model="popup.addressInput" position="right" closeable close-icon="arrow-left"
                       close-icon-position="top-left" :style="{width:'100%',height:'100%'}">
                <addressInput @onChange="onChangeAddress" :address.sync="item" :key="item.id" />
            </van-popup>
        </div>
    </div>
</template>
<script>
    import addressInput from './input'

    export default {
        name: "address-selector",
        components: {
            addressInput
        },
        props: ['customer', 'address'],
        data() {
            return {
                list: [],
                item: {
                    id: 0
                },
                loading: false,
                finished: true,
                finishedText: '',
                pages: 0,
                page: 1,
                popup: {
                    addressInput: false,
                }
            }
        },
        created() {

        },
        mounted: function () {
            this.load()
        },
        methods: {
            load() {
                let that = this;
                that.list = [];
                that.loading = true;
                that.finished = false;
                let options = {page: that.page, uid: that.customer.uid};
                that.$api.address.search(options).then(response => {
                    that.loading = false;
                    that.finished = true;
                    let res = response.data;
                    if (res.code == 1) {
                        if (res.data.list.length == 0) {
                            that.finishedText = '暂无数据'
                        } else {
                            that.finishedText = ''
                        }
                        that.pages = res.data.pages;
                        that.list = res.data.list
                    } else {
                        that.$toast(res.msg)
                    }
                })
            },
            onChange(item) {
                this.$emit('onChange', item);
            },
            onChangeAddress(){
                this.popup.addressInput = false;
                this.load();
            },
            onEdit(item) {
                this.item = item;
                this.popup.addressInput = true;
            },
            onDelete(item) {
                let that = this;
                that.$api.address.remove(item).then(response => {
                    let res = response.data;
                    if (res.code == 1) {
                        that.$toast.success(res.msg);
                        this.load();
                    } else {
                        that.$toast.fail(res.msg);
                    }
                });
            },
            onAdd() {
                this.item = {id: 0, uid: this.customer.uid};
                this.popup.addressInput = true;
            }
        },
        watch: {
            customer: {
                handler: function (newValue, oldValue) {
                    if (newValue !== oldValue) this.load()
                },
                deep: true
            }
        }

    }
</script>

<style scoped>
    .van-address-item .van-radio__icon--checked .van-icon {
        color: #fff;
        background-color: #1989fa;
        border-color: #1989fa;
    }

    .address-selector {
        height: 100%;
        width: 100%;
    }

    .address-selector-list {
        position: fixed;
        width: 100%;
        bottom: 90px;
        overflow: scroll;
        top: 46px;
    }

    .address-selector-pagination {
        position: fixed;
        bottom: 50px;
        width: 100%;
    }

    .address-selector-btn {
        position: fixed;
        bottom: 0;
        height: 50px;
    }

    .van-address-list {
        padding-bottom: 0 !important;
    }

    .van-address-item__address .van-tag{
        margin-right: 5px;
    }

    .van-address-item__address .van-tag:last-child{
        margin-right: 0;
    }
</style>
