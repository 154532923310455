<template>
    <van-picker show-toolbar :columns="columns" @cancel="onCancel" @change="onChange" @confirm="onConfirm"
                :loading="loading"/>
</template>
<script>
    export default {
        name: "area-selector",
        props: ['address'],
        data() {
            return {
                region: [],
                loading: false,
                options: [
                    {id: 0, text: '', level: 1},
                    {id: 0, text: '', level: 2},
                    {id: 0, text: '', level: 3}
                ],
                columns: []
            }
        },
        mounted: function () {
            this.init();
        },
        methods: {
            init() {
                let that = this;
                that.options = [];
                that.options.push({id: that.address.provinceId || 0, text: that.address.province || '', level: 1});
                that.options.push({id: that.address.cityId || 0, text: that.address.city || '', level: 2});
                that.options.push({id: that.address.countyId || 0, text: that.address.county || '', level: 3});
                if (that.region.length == 0) {
                    that.loading = true;
                    that.$api.region.loadAll().then(response => {
                        that.loading = false;
                        let res = response.data;
                        if (res.code == 1) {
                            that.region = res.data;
                            that.display();
                        } else {
                            return that.$toast.fail(res.msg);
                        }
                    });
                } else {
                    that.display();
                }
            },
            display() {
                let that = this;
                that.loading = true;
                that.columns = [
                    {
                        values: [],
                        className: 'province',
                        defaultIndex: 0
                    },
                    {
                        values: [],
                        className: 'city',
                        defaultIndex: 0
                    },
                    {
                        values: [],
                        className: 'area',
                        defaultIndex: 0
                    }
                ];
                if (that.region.length > 0) {
                    // 初始化省份
                    that.region.forEach(function (v) {
                        if (v.pid == 0) {
                            let index = that.columns[0].values.push({text: v.name, id: v.id, level: v.level});
                            if (!that.options[0].id) {
                                that.options[0] = {
                                    id: v.id,
                                    text: v.name,
                                    level: v.level,
                                };
                            }
                            if (that.options[0].id == v.id) {
                                that.columns[0].defaultIndex = index - 1;
                            }
                        }
                    });
                    // 初始化城市
                    that.region.forEach(function (v) {
                        if (v.pid == that.options[0].id) {
                            let index = that.columns[1].values.push({text: v.name, id: v.id, level: v.level});
                            if (!that.options[1].id) {
                                that.options[1] = {
                                    id: v.id,
                                    text: v.name,
                                    level: v.level
                                };
                            }
                            if (that.options[1].id == v.id) {
                                that.columns[1].defaultIndex = index - 1;
                            }
                        }
                    });
                    // 初始化地区
                    that.region.forEach(function (v) {
                        if (v.pid == that.options[1].id) {
                            let index = that.columns[2].values.push({text: v.name, id: v.id, level: v.level});
                            if (!that.options[2].id) {
                                that.options[2] = {
                                    id: v.id,
                                    text: v.name,
                                    level: v.level
                                };
                            }
                            if (that.options[2].id == v.id) {
                                that.columns[2].defaultIndex = index - 1;
                            }
                        }
                    });
                }
                that.loading = false;
            },
            onCancel() {
                this.$emit('onCancel');
            },
            onConfirm(values) {
                this.$emit('onConfirm', values);
            },
            onChange(picker, values) {

                this.loading = true;

                // 切换省-->重新加载市和区
                if (this.options[0].id != values[0].id) {
                    this.options[0] = values[0];
                    let city = this.getRegion(this.options[0]);
                    picker.setColumnValues(1, city);
                    this.options[1] = city[0];
                    let area = this.getRegion(this.options[1]);
                    picker.setColumnValues(2, area);
                    this.options[2] = area[0];
                }

                // 切换市-->重新加载区
                else if (this.options[1].id != values[1].id) {
                    this.options[1] = values[1];
                    let area = this.getRegion(this.options[1]);
                    picker.setColumnValues(2, area);
                    this.options[2] = area[0];
                }

                // 切换区
                else if (this.options[2].id != values[2].id) {
                    this.options[2] = values[2];
                }

                this.loading = false;
            },
            getRegion(item) {
                let list = [];
                if (this.region.length > 0) {
                    this.region.forEach(function (v) {
                        if (v.pid == item.id) {
                            list.push({text: v.name, id: v.id, level: v.level});
                        }
                    });
                }
                return list;
            }

        }
    }
</script>

<style scoped>

</style>
