<template>
    <div class="address-input">
        <van-nav-bar :title="info.id > 0 ? '修改地址' : '新增地址'"/>
        <div class="address-input-form">
            <van-cell-group>
                <van-field :value="info.type == 1 ? 'MCAKE直送' : '站点自提'" placeholder="请选择配送方式" required label="配送方式"
                           readonly @click="popup.type=true"/>
                <van-field v-model="info.consignee" placeholder="请输入收货人" required label="收货人" maxlength="10"/>
                <van-field v-model="info.mobile" placeholder="请输入手机号码" required label="手机" type="tel" maxlength="11"/>
                <van-field v-model="info.phone" placeholder="请输入座机号码" label="座机" type="tel" maxlength="20"/>
                <van-field v-if="info.type == 2" :value="info.cityId ? info.city : ''" placeholder="请选择配送城市" required
                           label="配送城市"
                           readonly @click="popupCity"/>
                <div v-if="info.type == 2" class="site-list">
                    <ul>
                        <li v-for="(item, index) in siteList" v-bind:key="index" @click="onChangeSite(item)"
                            :class="{active:item.siteId==info.siteId}"><em>
                            <van-icon name="location" v-if="item.siteId==info.siteId"/>
                            {{item.siteName}}</em><span>{{item.address}}</span>
                        </li>
                    </ul>
                </div>
                <van-field v-if="info.type == 1" :value="info.province+'/'+info.city+'/'+info.county"
                           placeholder="请选择省市区" required label="地区"
                           readonly @click="popup.area=true"/>
                <van-field v-if="info.type == 1" v-model="info.address" placeholder="请输入详细地址" required label="详细地址"
                           type="textarea"
                           maxlength="50" autosize show-word-limit />
                <van-switch-cell v-model="isDefault" @change="onChangeDefault" title="默认地址"/>
            </van-cell-group>
        </div>
        <div class="address-input-btn">
            <van-button type="info" icon="success" size="large" @click="onSumbit"
                        style="position:fixed;bottom:0;"
                        text="提交"/>
        </div>
        <div class="address-input-popup">
            <!-- 配送方式选择器 -->
            <van-popup v-model="popup.type" position="bottom" :style="{ height: '50%' }">
                <van-picker show-toolbar :columns="typeList" @cancel="popup.type=false"
                            @confirm="onConfirmType" :default-index="typeIndex"/>
            </van-popup>
            <!-- 城市选择器 -->
            <van-popup v-model="popup.city" position="bottom" :style="{ height: '50%' }">
                <van-picker show-toolbar :columns="cityList" @cancel="popup.city=false"
                            @confirm="onConfirmCity"/>
            </van-popup>
            <!-- 省市区选择器 -->
            <van-popup v-model="popup.area" position="bottom" :style="{ height: '50%' }">
                <areaSelector @onCancel="onCancelRegion" @onConfirm="onConfirmRegion" :address.sync="info"/>
            </van-popup>
        </div>
    </div>
</template>
<script>
    import areaSelector from '../../../common/areaSelector'

    export default {
        name: "address-input",
        components: {
            areaSelector
        },
        props: ['address'],
        data() {
            return {
                info: {
                    id: this.address.id || 0,
                    uid: this.address.uid || 0,
                    siteId: this.address.siteId || 0,
                    type: this.address.type || 1,
                    isDefault: this.address.isDefault || 0,
                    consignee: this.address.consignee || '',
                    mobile: this.address.mobile || '',
                    phone: this.address.phone || '',
                    provinceId: this.address.provinceId || 0,
                    province: this.address.province || '省',
                    cityId: this.address.cityId || 0,
                    city: this.address.city || '市',
                    countyId: this.address.countyId || 0,
                    county: this.address.county || '区',
                    address: this.address.address || '',
                    locator: this.address.locator || '',
                    lng: this.address.lng || '',
                    lat: this.address.lat || '',
                    precise: this.address.precise || 0,
                },
                isDefault: this.address.isDefault && this.address.isDefault == 1 ? true : false,
                cityList: [],
                siteList: [],
                citySiteList: [],
                typeList: [{type: 1, text: 'MCAKE直送'}, {type: 2, text: '站点自提'}],
                typeIndex: (this.address.type || 1) - 1,
                popup: {
                    city: false,
                    area: false,
                    type: false,
                }
            }
        },
        mounted: function () {
            this.init();
        },
        methods: {
            init() {
                if (this.info.type == 2) {
                    this.loadSite();
                }
            },
            loadCity() {
                let that = this;
                if (that.cityList.length == 0) {
                    that.$api.region.loadCity(0).then(response => {
                        that.loading = false;
                        let res = response.data;
                        if (res.code == 1) {
                            that.cityList = res.data;
                        } else {
                            return that.$toast.fail(res.msg);
                        }
                    });
                }
            },
            loadSite() {
                let that = this;
                if (that.info.cityId > 0) {
                    that.$api.address.loadSite(that.info.cityId).then(response => {
                        that.loading = false;
                        let res = response.data;
                        if (res.code == 1) {
                            that.siteList = res.data;
                        } else {
                            return that.$toast.fail(res.msg);
                        }
                    });
                }
            },
            onSumbit() {
                let that = this;
                that.$api.address.edit(that.info).then(response => {
                    let res = response.data;
                    if (res.code == 1) {
                        that.$toast.success(res.msg);
                        return that.$emit('onChange');
                    } else {
                        return that.$toast.fail(res.msg);
                    }
                });
            },
            onConfirmRegion(options) {
                this.info.provinceId = options[0].id;
                this.info.province = options[0].text;
                this.info.cityId = options[1].id;
                this.info.city = options[1].text;
                this.info.countyId = options[2].id;
                this.info.county = options[2].text;
                this.popup.area = false;
            },
            onCancelRegion() {
                this.popup.area = false;
            },
            onConfirmType(value) {
                this.popup.type = false;
                this.info.type = value.type;
                this.loadSite();
            },
            onConfirmCity(value) {
                this.info.cityId = value.index;
                this.info.city = value.text;
                this.popup.city = false;
                this.loadSite();
            },
            popupCity() {
                this.loadCity();
                this.popup.city = true;
            },
            onChangeSite(item) {
                Object.assign(this.info, item);
            },
            onChangeDefault(checked){
                if(checked){
                    this.info.isDefault = 1;
                }else{
                    this.info.isDefault = 0;
                }
            }
        },
        watch: {
            'info.address': function (newVal, oldVal) {
                if (newVal != oldVal && this.info.type == 1) {
                    this.info.precise = 0;
                    this.info.lng = '';
                    this.info.lat = '';
                }
            }
        }
    }
</script>

<style scoped>


    .address-input {
        height: 100%;
        width: 100%;
    }

    .site-list {
        padding: 6px;
        font-size: 14px;
        max-height: 225px;
        overflow-y: auto;
    }

    .site-list ul {

    }

    .site-list ul li {
        display: flex;
        border: 1px solid #eee;
        margin: 0 0 6px 0;
        height: 50px;
        border-radius: 5px;
        line-height: 25px;
    }

    .site-list ul li.active {
        border: 1px solid #1989fa;
        background-color: #1989fa40;
    }

    .site-list ul li em {
        flex: 1;
        text-align: center;
        line-height: 50px;
        font-style: normal;
    }

    .site-list ul li span {
        flex: 3;
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: left;
        justify-content: left;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
    }
</style>
